import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { Button, DotLoading, Form, Input, Toast } from "antd-mobile";
import StrUtil from "../../../util/StrUtil";
import { ComparisonResponse, validationApi } from "../../../api/validation";
import IUtil from "../../../util/IUtil";
import png_logo from "../../../assets/image.png@2x.png";

function Index() {
	useEffect(() => {
		// alert(window.location.href)
		let regex = new RegExp("ticket=([^&#/?]+)");
		let array = regex.exec(window.location.href);
		let ticket;
		if (array) {
			ticket = array[1];
		}
		const encryptedUserId = localStorage.getItem("encryptedUserId")||IUtil.getParam("encryptedUserId")
 		if (ticket && encryptedUserId) {
			// cas登录成功后会进入这里
			const logoutUrl = process.env.REACT_APP_frontUrl + "/#/logout";
			let service = localStorage.getItem("service");
			if (service) {
				localStorage.removeItem("service");
				localStorage.removeItem("encryptedUserId");

				validationApi
					.oldComparisonCas({
						ticket: ticket,
						encryptedUserId: encryptedUserId,
						service: service,
					})
					.then((res) => {
						let data: ComparisonResponse = res.data.body;
						let content = "";
						if (res.data.code !== 200) {
							content = `系统发生错误：${res.data.info}，请退出后重新进入企业微信登录`;
							IUtil.alertDialog(content, () => {
								window.location.replace(process.env.REACT_APP_casUrl + "/logout?service=" + encodeURIComponent(logoutUrl));
							});
							return;
						}
						setNumber(data.number);
						setLoading(true);
					});
			} else {
				IUtil.alertDialog("service not found", () => {
					window.location.replace(process.env.REACT_APP_casUrl + "/logout?service=" + encodeURIComponent(logoutUrl));
				});
			}
		} else {
			if (!StrUtil.isEmpty(encryptedUserId)) {
				// const redirectUri: string = process.env.REACT_APP_frontUrl + "/#/login/cqjgx?encryptedUserId=" + encryptedUserId;
				const redirectUri: string = process.env.REACT_APP_frontUrl + "/#/login/cqjgx";
				const service = encodeURIComponent(redirectUri);
				localStorage.setItem("service", service);
				localStorage.setItem("encryptedUserId", encryptedUserId);
				const authUrl = process.env.REACT_APP_casUrl + `/login?service=${service}`;
				// 跳转到登录界面
				window.location.replace(authUrl);
			}
		}
	}, []);

	const [loading, setLoading] = useState(true);
	const [number, setNumber] = useState("");
	const [num, setNum] = useState(0);
	const [form] = Form.useForm();

	const sendCode = () => {
		form.validateFields().then((nameList?: any[]) => {
			validationApi.ValidationPhone({ number, phone: form.getFieldsValue().phone }).then((res) => {
				Toast.show({
					content: "发送成功",
					duration: 5000,
				});
			});
			numCollapses(60);
		});
	};
	const numCollapses = (_num: number) => {
		setNum(_num);
		sleep(1000).then(() => {
			_num && numCollapses(_num - 1);
		});
	};
	const onFinish = (values: any) => {
		IUtil.confirmDialog("是否提交？", () => {
			validationApi.validationPhoneCheck({ number, ...form.getFieldsValue() }).then(() => {
				Toast.show({
					content: "认证成功，即将跳转到学校企业中，如长时间未自动跳转，请退出企业微信后重新进入",
					duration: 5000,
				});
			});
		});
	};

	if (!loading) {
		return (
			<div className={styles.Loading}>
				<DotLoading color='currentColor' />
			</div>
		);
	} else {
		return (
			<div className={styles.Main}>
				{/* <div className={styles.Tip}>填写信息</div> */}
				<img src={png_logo} alt='' width={154} style={{ marginBottom: "34px", marginTop: "68px" }} />
				<Form
					form={form}
					layout='horizontal'
					className={styles.Content}
					requiredMarkStyle='none'
					onFinish={onFinish}
					onFinishFailed={(error) => {
						console.log(error);
					}}
					footer={
						<Button className={styles.Submit} block type='submit' size={"large"} style={{ marginTop: "4px" }}>
							下一步
						</Button>
					}>
					<Form.Item name={"phone"} rules={[{ required: true }]} className={styles.Code}>
						<Input placeholder='请输入微信所绑定手机号' clearable />
					</Form.Item>
					<Form.Item name={"code"} className={styles.Code} extra={<div className={styles.extraPart}>{num ? <a>{num} </a> : <a onClick={sendCode}>获取验证码 </a>}</div>}>
						<Input placeholder='请输入验证码' clearable />
					</Form.Item>
				</Form>
			</div>
		);
	}
}
function sleep(time: number) {
	return new Promise((resolve) => setTimeout(resolve, time));
}
export default Index;
