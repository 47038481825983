import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {HashRouter, Route, Routes} from "react-router-dom";
import Root from "./pages/Root";
import Cas from "./pages/login/Cas";
import Logout from "./pages/Logout";
import NewBorn from "./pages/NewBorn";
import Home from "./pages/Home";
import Test from "./pages/login/Test";
import Ynnu from "./pages/login/Ynnu";
import Cqjgx from "./pages/login/cqjgx";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <HashRouter>
            <Routes>
                <Route path="/" element={<Root/>}>
                    <Route path="login/logout" element={<Logout/>}/>
                    <Route path="login/cas" element={<Cas/>}/>
                    <Route path="login/ynnu" element={<Ynnu/>}/>
                    <Route path="login/cqjgx" element={<Cqjgx/>}/>
                    <Route path="login/test" element={<Test/>}/>
                    <Route path="newborn" element={<NewBorn/>}/>
                    <Route path="home" element={<Home/>}/>
                </Route>
            </Routes>
        </HashRouter>
    </React.StrictMode>
);

console.log(process.env);
