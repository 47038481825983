import axios from 'axios'
import {Dialog} from "antd-mobile";
import qs from 'qs';
import ToastUtil from "./ToastUtil";

const excludeUrls: string[] = []

const service = axios.create({
    baseURL: process.env.REACT_APP_baseUrl, timeout: 10000
})

service.interceptors.request.use((config) => {
    if (!config.url) {
        return Promise.reject('url为空')
    }
    if (!config.headers) {
        config.headers = {}
    }
    if (config.method === 'get') {
        //如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
        config.paramsSerializer = function (params) {
            return qs.stringify(params, {arrayFormat: 'repeat'})
        }
    }
    ToastUtil.showLoading()
    return config
}, error => {
    ToastUtil.hideLoading()
    return Promise.reject(error)
})

async function handleCode(code: number, errMsg: string) {
    if (errMsg==='解密失败') return;
    switch (code) {
        default:
            Dialog.alert({
                content: errMsg,
            })
            break
    }

}

service.interceptors.response.use(response => {
    ToastUtil.hideLoading()
    const data = response.data
    const errMsg = data.info
    if (data.code != 200) {
        handleCode(data.code, errMsg);
        return Promise.reject(errMsg)
    }
    return response
}, error => {
    ToastUtil.hideLoading()
    return Promise.reject(error)
})

export default service
