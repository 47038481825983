// 配置vconsole
import Vconsole from 'vconsole'
import {Dialog} from "antd-mobile";
import StrUtil from "./StrUtil";


class IUtil {

    public static  getParam02 = (field: string, searchParams: URLSearchParams): string | null => {
        let value = searchParams.get(field);
        if (StrUtil.isEmpty(value)) {
            let searchParams02 = new URLSearchParams(window.location.search);
            value = searchParams02.get(field)
        }
        return value
    }

    public static  getParam = (param: string): any => {
        const reg = new RegExp('(^|&)' + param + '=([^&]*)(&|$)');
        const r = window.location.search.substring(1).match(reg) || window.location.hash.substring((window.location.hash.search(/\?/)) + 1).match(reg);
        if (r != null) {
            return decodeURIComponent(r[2]);
        }
    }

    public static confirmDialog = async (content: string, access?: Function, reject?: Function) => {
        const result = await Dialog.confirm({
            content: content,
        })
        if (result) {
            if (access) {
                access()
            }
        } else {
            if (reject) {
                reject()
            }
        }
    }

    public static alertDialog = async (content: string, access?: Function) => {
        await Dialog.alert({
            content: content,
        })
        if (access) {
            access()
        }
    }


}

export default IUtil
