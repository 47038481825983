import React, {RefObject, useEffect, useRef, useState} from 'react';
import styles from './index.module.scss';
import {Button, DatePicker, DatePickerRef, Dialog, Form, Input, Picker, Toast} from "antd-mobile";
import IUtil from "../../util/IUtil";
import {ComparisonConfig, ComparisonRequest, validationApi} from "../../api/validation";
import {PickerColumnItem} from "antd-mobile/es/components/picker-view";
import moment from "moment/moment";
import {DialogShowHandler} from "antd-mobile/es/components/dialog";

const idTypeColumns = [
    [
        {label: '身份证', value: '身份证'},
        {label: '护照', value: '护照'},
        {label: '其他', value: '其他'},

    ],
]

function Index() {
    const [config, setConfig] = useState<ComparisonConfig>()
    const [encryptedUserId, setEncryptedUserId] = useState<string>()
    const [showIdTypeSelect, setShowIdTypeSelect] = useState<boolean>(false)
    const handler = useRef<DialogShowHandler>()

    useEffect(() => {
        const encryptedUserId = IUtil.getParam('encryptedUserId');
        setEncryptedUserId(encryptedUserId)
        validationApi.comparisonConfig().then(res => {
            setConfig(res.data.body)
        })
    }, []);

    const onFinish = (values: ComparisonRequest) => {
        if (!encryptedUserId) {
            Toast.show('数据异常')
            return
        }
        values.encryptedUserId = encryptedUserId
        if (values.idTypeItem) {
            values.idType = values.idTypeItem[0]
        }
        if (values.birthdayItem) {
            values.birthday = moment(values.birthdayItem).format('YYYY-MM-DD')
        }

        IUtil.confirmDialog("是否提交？", () => {
            validationApi.newComparison(values).then(res => {
                switch (process.env.REACT_APP_env) {
                    case "cqjgx":
                        Imperative(res.data.body)
                        break;
                    default :
                        Toast.show({
                            content: '认证成功，即将跳转到学校企业中，如长时间未自动跳转，请退出企业微信后重新进入',
                            duration: 5000
                        })
                }
            })
        })
    }
    const [form] = Form.useForm();
    // 短信验证
    function Imperative(number: string) {
        let isCode = false
        const ValidationPhone = () => {
            form.validateFields().then((nameList?: any[]) => {
                validationApi.ValidationPhone({number, phone: form.getFieldsValue().phone})
                    .then((res) => {
                        isCode = true
                        handler.current?.close()
                        updDialog()
                    })
            })
        }

        const onFinish = () => {
            console.log(form.getFieldsValue())
            validationApi.validationPhoneCheck({number, ...form.getFieldsValue()}).then(() => {
                Toast.show({
                    content: '认证成功，即将跳转到学校企业中，如长时间未自动跳转，请退出企业微信后重新进入',
                    duration: 5000
                })
            })
        }

        const updDialog = () => {
            handler.current = Dialog.show({
                style:{width: '90vw'},
                className:styles.Dialog,
                content: <>
                    <Form form={form} layout='horizontal' >
                        <Form.Item
                            hidden={isCode}
                            name={'phone'}
                            label='手机号'
                            rules={[{required: true}]}
                            className={styles.Code}
                            extra={<div className={styles.extraPart}
                                        onClick={ValidationPhone}>
                                <a>发送验证码</a>
                            </div>}
                        >
                            <Input placeholder='请输入' clearable/>
                        </Form.Item>
                        <Form.Item
                            hidden={!isCode}
                            name={'code'}
                            label='短信验证码'

                        >
                            <Input placeholder='请输入验证码' clearable style={{width: '70vw'}}/>
                        </Form.Item>
                    </Form>
                </>,
                actions: [
                    {
                        style: {display: isCode ? undefined : 'none'},
                        key: 'ok',
                        text: '确定',
                        onClick: () => {
                            onFinish()
                        },
                    },
                    {
                        key: 'close',
                        text: '关闭',
                        onClick: () => {
                            handler.current?.close()
                        },
                    },
                ],
            })
        }
        updDialog()
    }

    if (!config || !encryptedUserId) {
        return <div/>
    }
    const idTypeValFormat = (item: (PickerColumnItem | null)[]) => {
        return (item && item.length > 0) ? <p style={{}}>{item[0]?.value}</p> :
            <p style={{color: '#cccccc'}}>请选择</p>
    }
    let min: Date = moment().subtract(75, 'years').toDate()
    let max: Date = moment().subtract(14, 'years').toDate()
    return (
        <div className={styles.Main}>
            <div className={styles.Tip}>填写信息</div>
            <Form initialValues={{idTypeItem: ['身份证']}} className={styles.Content} layout={'horizontal'}
                  requiredMarkStyle='none'
                  onFinish={onFinish}
                  onFinishFailed={(error) => {
                      console.log(error)
                  }}
                  footer={
                      <Button className={styles.Submit} block type='submit' size={'large'}
                              style={{marginTop: '4px'}}>提交</Button>
                  }>
                <Form.Item hidden={!config?.name} name='name' label={getLabel('姓名')}
                           rules={[{required: config?.name}]}
                           className='pc5yuRhxi9BHgnkZ8hvxw5tqdPiss3dg'>
                    <Input placeholder='请输入姓名' clearable style={{'--text-align': 'right'}}/>
                </Form.Item>

                <Form.Item hidden={!config?.number} name='number' label={getLabel('学号')}
                           rules={[{required: config?.number}]} className='pc5yuRhxi9BHgnkZ8hvxw5tqdPiss3dg'>
                    <Input placeholder='请输入学号' clearable style={{'--text-align': 'right'}}/>
                </Form.Item>

                <Form.Item hidden={!config?.identityCard} name='idTypeItem' label={getLabel('证件类型')}
                           rules={[{required: config?.identityCard}]}
                           className='DAEoxJXsAXURIRvXJDyZCAcyAX pc5yuRhxi9BHgnkZ8hvxw5tqdPiss3dg'
                           trigger='onConfirm'
                           onClick={() => setShowIdTypeSelect(true)}>
                    <Picker onClose={() => setShowIdTypeSelect(false)}
                            columns={idTypeColumns}
                            visible={showIdTypeSelect}>
                        {idTypeValFormat}
                    </Picker>
                </Form.Item>
                <Form.Item hidden={!config?.identityCard} name='identityCard' label={getLabel('证件号')}
                           rules={[{required: config?.identityCard}]} className='Oeqet6cBRPvA4BiwXdGpm9leNo6kxiLT'>
                    <Input placeholder='请输入证件号' clearable style={{'--text-align': 'right'}}/>
                </Form.Item>
                <Form.Item
                    hidden={!config?.birthday}
                    name='birthdayItem'
                    label={getLabel('生日')}
                    trigger='onConfirm'
                    onClick={(e, datePickerRef: RefObject<DatePickerRef>) => {
                        datePickerRef.current?.open()
                    }}
                    className={'DAEoxJXsAXURIRvXJDyZCAcyAX'}
                >
                    <DatePicker min={min} max={max}>
                        {value =>
                            value ? moment(value).format('YYYY-MM-DD') : <p style={{color: '#ccc'}}>{'请选择日期'}</p>
                        }
                    </DatePicker>
                </Form.Item>

            </Form>
        </div>
    );
}

const getLabel = (label: string) => <div className={styles.FormLableText}>
    <div>{label}</div>
    <div className={styles.FormLableText_bt}>*</div>
</div>

export default Index;
