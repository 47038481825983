import React from 'react';
import styles from './index.module.scss';


function Index() {
    return (
        <div className={styles.Main}>
            请退出后重新进入企业微信登录
        </div>
    );
}

export default Index;
