import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import school_logo from '../../../assets/schoolLogo.svg';
import StrUtil from "../../../util/StrUtil";
import {useNavigate} from "react-router-dom";
import {Button, Input, Toast} from "antd-mobile";
import {EyeInvisibleOutline, EyeOutline} from "antd-mobile-icons";
import {ComparisonResponse, validationApi} from "../../../api/validation";
import IUtil from "../../../util/IUtil";


function Index() {
    let navigate = useNavigate();
    const [visible, setVisible] = useState(false)
    const [disabled, setDisabled] = useState<boolean>(true)
    const [username, setUsername] = useState<string>()
    const [password, setPassword] = useState<string>()
    useEffect(() => {
    }, [])
    const encryptedUserId = IUtil.getParam('encryptedUserId');
    const login = () => {
        if (!encryptedUserId) {
            Toast.show("未获取到您的用户信息");
            return
        }
        if (username && !StrUtil.isEmpty(username) && password && !StrUtil.isEmpty(password)) {
            validationApi.oldComparisonYnnu({username, password, encryptedUserId}).then(res => {
                let data: ComparisonResponse = res.data.body;
                if (!data.success) {
                    let content = ''
                    if (res.data.code !== 200) {
                        content = `系统发生错误：${res.data.info}`
                    } else {
                        if (data.number !== data.userId) {
                            content = `你登录的账号（${data.number}）和你的企业微信账号（${data.userId}）不一致，请检查输入的信息是否正确`
                        } else {
                            content = '认证成功，即将跳转到学校企业中，如长时间未自动跳转，请退出企业微信后重新进入'
                        }
                    }
                    IUtil.alertDialog(content, () => {
                        // todo
                    })
                }
            })

        } else {
            Toast.show('账号密码不能为空')
        }
    }


    const changeUsername = (username: string) => {
        setUsername(username)
        if (password && username) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }
    const changePassword = (password: string) => {
        setPassword(password)
        if (password && username) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }
    return (
        <div className={styles.Main}>
            <div className={styles.Header}>
                <img width={64} src={school_logo}/>
                <div className={styles.HeaderWelcome}>
                    欢迎登录云南师范大学
                </div>
            </div>
            <div className={styles.Content}>
                <Input
                    onChange={changeUsername}
                    className={styles.Username}
                    placeholder='请输入校园网认证用户名'
                />
                <div className={styles.Password}>
                    <Input
                        onChange={changePassword}
                        className={styles.PasswordInput}
                        placeholder='请输入校园网认证密码'
                        type={visible ? 'text' : 'password'}
                    />
                    <div className={styles.Eye}>
                        {!visible ? (
                            <EyeInvisibleOutline onClick={() => setVisible(true)}/>
                        ) : (
                            <EyeOutline onClick={() => setVisible(false)}/>
                        )}
                    </div>
                </div>
                <Button disabled={disabled} className={styles.Btn} onClick={login}>登录</Button>
            </div>
        </div>

    );
}

export default Index;
