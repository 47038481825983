import React, {useEffect, useRef} from 'react';
import styles from './index.module.scss';
import {Dialog, DotLoading, Form, Input, Toast} from "antd-mobile";
import StrUtil from "../../../util/StrUtil";
import {ComparisonResponse, validationApi} from "../../../api/validation";
import IUtil from "../../../util/IUtil";
import {DialogShowHandler} from "antd-mobile/es/components/dialog";


function Index() {
    useEffect(() => {
        // alert(window.location.href)
        let regex = new RegExp('ticket=([^&#/?]+)')
        let array = regex.exec(window.location.href);
        let ticket
        if (array) {
            ticket = array[1]
        }
        const encryptedUserId = IUtil.getParam('encryptedUserId');
        if (ticket && encryptedUserId) {
            // cas登录成功后会进入这里
            const logoutUrl = process.env.REACT_APP_frontUrl + "/#/logout";
            let service = localStorage.getItem("service");
            if (service) {
                validationApi.oldComparisonCas({
                    ticket: ticket,
                    encryptedUserId: encryptedUserId,
                    service: service
                }).then(res => {
                    let data: ComparisonResponse = res.data.body;
                    if (!data.success) {
                        let content = ''
                        if (res.data.code !== 200) {
                            content = `系统发生错误：${res.data.info}，请退出后重新进入企业微信登录`
                            IUtil.alertDialog(content, () => {
                                window.location.replace(process.env.REACT_APP_casUrl + '/logout?service=' + encodeURIComponent(logoutUrl))
                            })
                        } else {
                            if (data.number !== data.userId) {
                                content = `你登录的账号（${data.number}）和你的企业微信账号（${data.userId}）不一致，请退出后重新进入企业微信登录`
                                IUtil.alertDialog(content, () => {
                                    window.location.replace(process.env.REACT_APP_casUrl + '/logout?service=' + encodeURIComponent(logoutUrl))
                                })
                            } else {
                                switch (process.env.REACT_APP_env) {
                                    case 'cqjgx':
                                        Imperative(data.number);
                                        break;
                                    default:
                                        content = '认证成功，即将跳转到学校企业中，如长时间未自动跳转，请退出企业微信后重新进入';
                                        IUtil.alertDialog(content, () => {
                                            window.location.replace(process.env.REACT_APP_casUrl + '/logout?service=' + encodeURIComponent(logoutUrl))
                                        })
                                }
                            }
                        }

                    } else if (process.env.REACT_APP_env === 'cqjgx') {
                        let content = ''
                        if (res.data.code !== 200) {
                            content = `系统发生错误：${res.data.info}，请退出后重新进入企业微信登录`
                            IUtil.alertDialog(content, () => {
                                window.location.replace(process.env.REACT_APP_casUrl + '/logout?service=' + encodeURIComponent(logoutUrl))
                            })
                        } else {
                            if (data.number !== data.userId) {
                                content = `你登录的账号（${data.number}）和你的企业微信账号（${data.userId}）不一致，请退出后重新进入企业微信登录`
                                IUtil.alertDialog(content, () => {
                                    window.location.replace(process.env.REACT_APP_casUrl + '/logout?service=' + encodeURIComponent(logoutUrl))
                                })
                            } else {
                                switch (process.env.REACT_APP_env) {
                                    case 'cqjgx':
                                        Imperative(data.number);
                                        break;
                                    default:
                                        content = '认证成功，即将跳转到学校企业中，如长时间未自动跳转，请退出企业微信后重新进入';
                                        IUtil.alertDialog(content, () => {
                                            window.location.replace(process.env.REACT_APP_casUrl + '/logout?service=' + encodeURIComponent(logoutUrl))
                                        })
                                }
                            }
                        }
                    }
                })
            } else {
                IUtil.alertDialog('service not found', () => {
                    window.location.replace(process.env.REACT_APP_casUrl + '/logout?service=' + encodeURIComponent(logoutUrl))
                })
            }

        } else {
            if (!StrUtil.isEmpty(encryptedUserId)) {
                const redirectUri: string = process.env.REACT_APP_frontUrl + "/#/login/cas?encryptedUserId=" + encryptedUserId
                const service = encodeURIComponent(redirectUri)
                localStorage.setItem("service", service)
                const authUrl = process.env.REACT_APP_casUrl + `/login?service=${service}`
                // 跳转到登录界面
                window.location.replace(authUrl)
            }
        }

    }, []);
    const [form] = Form.useForm();
    const handler = useRef<DialogShowHandler>()

    // 短信验证
    function Imperative(number: string) {
        let isCode = false
        const ValidationPhone = () => {
            form.validateFields().then((nameList?: any[]) => {
                validationApi.ValidationPhone({number, phone: form.getFieldsValue().phone})
                    .then((res) => {
                        isCode = true
                        handler.current?.close()
                        updDialog()
                    })
            })
        }

        const onFinish = () => {
            console.log(form.getFieldsValue())
            validationApi.validationPhoneCheck({number, ...form.getFieldsValue()}).then(() => {
                Toast.show({
                    content: '认证成功，即将跳转到学校企业中，如长时间未自动跳转，请退出企业微信后重新进入',
                    duration: 5000
                })
            })
        }

        const updDialog = () => {
            handler.current = Dialog.show({
                style: {width: '90vw'},
                className: styles.Dialog,
                content: <>
                    <Form form={form} layout='horizontal'>
                        <Form.Item
                            hidden={isCode}
                            name={'phone'}
                            label='手机号'
                            rules={[{required: true}]}
                            className={styles.Code}
                            extra={<div className={styles.extraPart}
                                        onClick={ValidationPhone}>
                                <a>发送验证码</a>
                            </div>}
                        >
                            <Input placeholder='请输入' clearable/>
                        </Form.Item>
                        <Form.Item
                            hidden={!isCode}
                            name={'code'}
                            label='短信验证码'

                        >
                            <Input placeholder='请输入验证码' clearable style={{width: '70vw'}}/>
                        </Form.Item>
                    </Form>
                </>,
                actions: [
                    {
                        style: {display: isCode ? undefined : 'none'},
                        key: 'ok',
                        text: '确定',
                        onClick: () => {
                            onFinish()
                        },
                    },
                    {
                        key: 'close',
                        text: '关闭',
                        onClick: () => {
                            handler.current?.close()
                        },
                    },
                ],
            })
        }
        alert(1)
        updDialog()
    }

    return (
        <div className={styles.Main}>
            <DotLoading color='currentColor'/>
        </div>
    );
}

export default Index;
