import {IBaseResponse} from '../common/DataType';
import request from '../util/request';

export type YnnuPwdAuthRequest = {
    username: string; //账号
    password: string; //密码
    encryptedUserId: string; //加密的企业微信用户id
};

export type ComparisonResponse = {
    success: boolean; //是否成功
    number: string; //登录学工号
    userId: string; //企业微信用户id
};

export type NeedValidationResponse = {
    need: boolean; //是否需要验证
    encryptedUserId: string; //加密的企业微信用户id
};

export type ComparisonRequest = {
    number: string; //学工号
    name: string; //姓名
    idType: string; //证件类型（身份证/护照）
    idTypeItem: string[]; //证件类型（身份证/护照）
    identityCard: string; //证件号
    birthday: string; //生日
    birthdayItem: Date; //生日
    encryptedUserId: string; //加密的企业微信用户id
};

export type ComparisonConfig = {
    number: boolean; //学号
    name: boolean; //姓名
    identityCard: boolean; //证件号
    birthday: boolean; //生日
};

export const validationApi = {
    /**
     * 是否开启新生二次验证
     */
    newbornEnabled: () => {
        return request.get<IBaseResponse<boolean>>('/validation/newbornEnabled');
    },
    /**
     * 新生比对
     * 需要填写的参数
     */
    comparisonConfig: () => {
        return request.get<IBaseResponse<ComparisonConfig>>('/validation/comparisonConfig');
    },
    /**
     * 新生比对
     */
    newComparison: (data: ComparisonRequest) => {
        return request.post<IBaseResponse<any>>('/validation/new/comparison', data);
    },
    /**
     * 老生教职工比对（cas方式）
     * 老生通过cas登录比对
     */
    oldComparisonCas: (params: { ticket: string; service: string; encryptedUserId: string }) => {
        return request.get<IBaseResponse<ComparisonResponse>>('/validation/old/comparison/cas', {params});
    },
    /**
     * 老生教职工比对（测试）
     */
    oldComparisonTest: (params: { number: string; encryptedUserId: string }) => {
        return request.get<IBaseResponse<ComparisonResponse>>('/validation/old/comparison/test', {params});
    },
    /**
     * 接口通过code判断是否需要验证
     * 开启了验证，会返回encryptedUserId
     */
    getEncryptedUserId: (params: { code: string }) => {
        return request.get<IBaseResponse<NeedValidationResponse>>('/validation/encryptedUserId', {params});
    },
    /**
     * 云师大的验证接口
     * 云师大专用
     */
    oldComparisonYnnu: (data: YnnuPwdAuthRequest) => {
        return request.post<IBaseResponse<ComparisonResponse>>('/validation/old/comparison/ynnu', data);
    },

    /**
     * 校验userld是否是学工号 true是false不是
     * 云师大专用
     */
    validationUserld: (params: { encryptedUserId: string }) => {
        return request.get<IBaseResponse>('/validation/userId', {params});
    },
    /**
     * 输入手机号获取验证码
     * cqjgx专用
     */
    ValidationPhone: (data: { number: string, phone: string }) => {
        return request.post<IBaseResponse<string>>('/validation/code', data);
    },
    /**
     * 校验验证码
     * cqjgx专用
     */
    validationPhoneCheck: (data: { number: string, phone: string, code: string }) => {
        return request.post<IBaseResponse<string>>('/validation/check/code', data);
    },
};
