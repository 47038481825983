import React, {useEffect} from 'react';
import {Outlet} from "react-router-dom";
import Vconsole from "vconsole";

export const authPages: RegExp[] = [/.*\/main\/.*/]

function Index() {
    if (process.env.REACT_APP_vconsole === 'open') {
        new Vconsole();
    }
    useEffect(() => {

    }, [])
    return (
        <Outlet/>

    );
}

export default Index;
