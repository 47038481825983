import {Toast} from "antd-mobile";

let requestCount = 0

class ToastUtil {
    public static showLoading = () => {
        if (requestCount === 0) {
            Toast.show({
                maskClickable: false,
                icon: 'loading',
                content: '',
                duration: 0,
            })
        }
        requestCount++

    }

    public static hideLoading = () => {
        requestCount--
        if (requestCount <= 0) {
            requestCount = 0
            Toast.clear()
        }
    }

}

export default ToastUtil





