class StrUtil {

    public static getHash = (str: string) => {
        let hash: number = 0, i: number, chr;
        if (str.length === 0) return hash;
        for (i = 0; i < str.length; i++) {
            chr = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    public static isEmpty = (str: string | null | undefined): boolean => {
        if (!str || str.trim().length === 0) {
            return true
        }
        return false
    }
}

export default StrUtil